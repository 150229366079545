import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Image,
  Input,
  Popconfirm,
  Row,
  Space,
  Typography,
  message,
} from 'antd';
import {
  FileImageOutlined,
  DeleteOutlined,
  PlusOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import ClickerQuestionFilters from './filter';
import ReactQuillEditor from '../../../components/reactQuill';
import './style.scss';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
const { Text, Title, Link } = Typography;
const allowedFiles = ['.png', '.jpg', '.jpeg', '.webp'];
const CreateEditQuestion = ({
  createQuestionmodalData,
  setCreateQuestionmodalData,
  fetchQuestionList,
}) => {
  useEffect(() => {
    if (!createQuestionmodalData?.edit?.show && !createQuestionmodalData?.edit?.id) {
      setCreateQuestionmodalData({
        ...createQuestionmodalData,
        data: {
          ...createQuestionmodalData?.data,
          question: null,
          options: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (createQuestionmodalData?.data?.filteredData?.questionType?.id === 1) {
      setCreateQuestionmodalData({
        ...createQuestionmodalData,
        data: {
          ...createQuestionmodalData?.data,
          question:
            createQuestionmodalData?.edit?.show && createQuestionmodalData?.edit?.id
              ? createQuestionmodalData?.data?.question
              : null,
          options:
            createQuestionmodalData?.edit?.show && createQuestionmodalData?.edit?.id
              ? createQuestionmodalData?.data?.options
              : [{ text: null, is_answer: true, image_url: null }],
        },
      });
    } else if (
      [2, 3]?.includes(createQuestionmodalData?.data?.filteredData?.questionType?.id)
    ) {
      setCreateQuestionmodalData({
        ...createQuestionmodalData,
        data: {
          ...createQuestionmodalData?.data,
          question:
            createQuestionmodalData?.edit?.show && createQuestionmodalData?.edit?.id
              ? createQuestionmodalData?.data?.question
              : null,
          options:
            createQuestionmodalData?.edit?.show && createQuestionmodalData?.edit?.id
              ? createQuestionmodalData?.data?.options
              : [
                  { text: null, is_answer: false, image_url: null },
                  { text: null, is_answer: false, image_url: null },
                ],
        },
      });
    } else if (
      [4]?.includes(createQuestionmodalData?.data?.filteredData?.questionType?.id)
    ) {
      setCreateQuestionmodalData({
        ...createQuestionmodalData,
        data: {
          ...createQuestionmodalData?.data,
          question:
            createQuestionmodalData?.edit?.show && createQuestionmodalData?.edit?.id
              ? createQuestionmodalData?.data?.question
              : null,
          options:
            createQuestionmodalData?.edit?.show && createQuestionmodalData?.edit?.id
              ? createQuestionmodalData?.data?.options
              : [
                  { text: 'True', is_answer: false, image_url: null },
                  { text: 'False', is_answer: false, image_url: null },
                ],
        },
      });
    }
  }, [
    createQuestionmodalData?.data?.filteredData?.questionType,
    createQuestionmodalData?.edit,
  ]);

  const showQuestionAnswerSection = () => {
    if (createQuestionmodalData?.data?.filteredData?.questionType) {
      return true;
    } else {
      return false;
    }
  };

  const questionChangeHandler = (content, delta, source, editor) => {
    setCreateQuestionmodalData({
      ...createQuestionmodalData,
      data: {
        ...createQuestionmodalData?.data,
        question: content,
      },
    });
  };

  const mcqSelectAnswerHandler = (index) => {
    const updatedData = { ...createQuestionmodalData.data };
    if ([2, 4].includes(createQuestionmodalData?.data?.filteredData?.questionType?.id)) {
      if (updatedData.options && Array.isArray(updatedData.options)) {
        updatedData.options = updatedData.options.map((option, idx) => {
          return {
            ...option,
            is_answer: idx === index,
          };
        });
      }
    }
    if (createQuestionmodalData?.data?.filteredData?.questionType?.id === 3) {
      if (updatedData.options && Array.isArray(updatedData.options)) {
        updatedData.options = updatedData.options.map((option, idx) => {
          if (idx === index) {
            return {
              ...option,
              is_answer: !option.is_answer, // Toggle the is_answer property
            };
          }
          return option;
        });
      }
    }
    setCreateQuestionmodalData({
      ...createQuestionmodalData,
      data: {
        ...createQuestionmodalData?.data,
        options: updatedData.options,
      },
    });
  };

  const mcqOptionHandler = (e, index) => {
    const updatedData = { ...createQuestionmodalData.data };
    if (
      createQuestionmodalData?.data?.filteredData?.questionType?.id === 1 &&
      // updatedData.options[index].answer?.length === 1 &&
      !/^\d?$/.test(e.target.value)
    ) {
      return;
    }
    if (updatedData.options && updatedData.options[index]) {
      updatedData.options[index].text = e.target.value;
    }
    setCreateQuestionmodalData({
      ...createQuestionmodalData,
      data: {
        ...createQuestionmodalData?.data,
        options: updatedData.options,
      },
    });
  };

  const handleAddOption = () => {
    if (createQuestionmodalData?.data?.options?.length >= 8) {
      message.error('Maximum 8 options allowed.');
      return;
    }
    let updatedDataOptions = createQuestionmodalData?.data?.options;
    updatedDataOptions = [
      ...updatedDataOptions,
      { text: null, is_answer: false, image_url: null },
    ];
    setCreateQuestionmodalData({
      ...createQuestionmodalData,
      data: {
        ...createQuestionmodalData?.data,
        options: updatedDataOptions,
      },
    });
  };

  const handleRemoveOption = (index) => {
    let updatedDataOptions = createQuestionmodalData?.data?.options;
    updatedDataOptions.splice(index, 1);
    setCreateQuestionmodalData({
      ...createQuestionmodalData,
      data: {
        ...createQuestionmodalData?.data,
        options: updatedDataOptions,
      },
    });
  };

  const attachImage = (e, index) => {
    const selectedImage = e.target?.files[0];
    const type =
      '.' + selectedImage?.name?.split('.')[selectedImage?.name?.split('.')?.length - 1];
    if (allowedFiles.includes(type)) {
      if (selectedImage?.size > 52428800) {
        message.error('File Size Cannot Exceed 50MB');
        return;
      } else {
        const formData = new FormData();
        formData.append('file', selectedImage);
        axiosInstance
          .post(`${endpoints.clickerQuestion.fileUpload}`, formData)
          .then((res) => {
            if (res.data.status_code === 200) {
              message.success(res.data.message);
              const updatedData = { ...createQuestionmodalData.data };
              if (updatedData.options && updatedData.options[index]) {
                updatedData.options[index].image_url = res.data.media_path;
              }
              setCreateQuestionmodalData({
                ...createQuestionmodalData,
                data: {
                  ...createQuestionmodalData?.data,
                  options: updatedData.options,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      message.error('Please upload images only');
    }
  };

  const removeImage = (optionIndex) => {
    const updatedData = { ...createQuestionmodalData.data };
    if (updatedData.options && updatedData.options[optionIndex]) {
      updatedData.options[optionIndex].image_url = null;
    }
    setCreateQuestionmodalData({
      ...createQuestionmodalData,
      data: {
        ...createQuestionmodalData?.data,
        options: updatedData.options,
      },
    });
  };

  const checkvalidation = () => {
    if (!createQuestionmodalData?.data?.filteredData?.academicYear) {
      message.error('Please enter academic year');
      return false;
    }
    if (!createQuestionmodalData?.data?.filteredData?.grade) {
      message.error('Please enter grade');
      return false;
    }
    if (!createQuestionmodalData?.data?.filteredData?.subject) {
      message.error('Please enter subject');
      return false;
    }
    if (!createQuestionmodalData?.data?.filteredData?.volume) {
      message.error('Please enter volume');
      return false;
    }
    if (!createQuestionmodalData?.data?.filteredData?.module) {
      message.error('Please enter module');
      return false;
    }
    if (!createQuestionmodalData?.data?.filteredData?.chapter) {
      message.error('Please enter chapter');
      return false;
    }
    if (!createQuestionmodalData?.data?.filteredData?.keyConcept) {
      message.error('Please enter key concept');
      return false;
    }
    if (!createQuestionmodalData?.data?.filteredData?.questionType) {
      message.error('Please enter question type');
      return false;
    }
    if (!createQuestionmodalData?.data?.filteredData?.questionType) {
      message.error('Please enter question type');
      return false;
    }
    if (!createQuestionmodalData?.data?.filteredData?.questionMarks) {
      message.error('Please enter question mark');
      return false;
    }
    if (
      !createQuestionmodalData?.data?.question ||
      createQuestionmodalData?.data?.question?.length === 0
    ) {
      message.error('Please enter question');
      return false;
    }
    if (
      createQuestionmodalData?.data?.options.some((option, index) => {
        return option?.image_url === null
          ? option.text === null || option.text?.trim() === ''
          : false;
      })
    ) {
      message.error('Please enter all answer value');
      return false;
    }
    if (!createQuestionmodalData?.data?.options.some((option) => option.is_answer)) {
      message.error('Please select answer');
      return false;
    }

    return true;
  };

  const createQuestion = (questionStatus) => {
    let validate = checkvalidation();
    if (!validate) {
      return;
    }
    let payload = {
      academic_year: createQuestionmodalData?.data?.filteredData?.academicYear?.id,
      grade: createQuestionmodalData?.data?.filteredData?.grade?.id,
      subject: createQuestionmodalData?.data?.filteredData?.subject?.subject?.id,
      module: createQuestionmodalData?.data?.filteredData?.module?.id,
      chapter: createQuestionmodalData?.data?.filteredData?.chapter?.id,
      topic: createQuestionmodalData?.data?.filteredData?.keyConcept?.id,
      question: createQuestionmodalData?.data?.question,
      // question_categories: 1,
      // question_level: 1,
      question_mark: createQuestionmodalData?.data?.filteredData?.questionMarks,
      question_type: createQuestionmodalData?.data?.filteredData?.questionType?.id,
      // image_url: '',
      question_status: questionStatus, //draft - 1, review -2, publish -3,
      options: createQuestionmodalData?.data?.options,
    };
    axiosInstance
      .post(endpoints.clickerQuestion.clickerQuestions, payload)
      .then((res) => {
        if (res.data.status_code === 200) {
          message.success(res.data.message);
          setCreateQuestionmodalData({
            ...createQuestionmodalData,
            show: false,
            edit: {
              id: null,
              show: false,
              question_status: null,
            },
            data: null,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editQuestion = () => {
    //draft - 1, published-2, reviewed-3,
    let validate = checkvalidation();
    if (!validate) {
      return;
    }
    let payload = {
      id: createQuestionmodalData?.edit?.id,
      question: createQuestionmodalData?.data?.question,
      question_mark: createQuestionmodalData?.data?.filteredData?.questionMarks,
      // question_type: 1,
      question_status: [2, 3].includes(createQuestionmodalData?.edit?.question_status)
        ? 2
        : 1,
      options: createQuestionmodalData?.data?.options,
    };
    axiosInstance
      .put(endpoints.clickerQuestion.clickerQuestions, payload)
      .then((res) => {
        if (res.data.status_code === 200) {
          message.success(res.data.message);
          setCreateQuestionmodalData({
            ...createQuestionmodalData,
            show: false,
            data: null,
            edit: {
              id: null,
              show: false,
              question_status: null,
            },
          });
          fetchQuestionList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Drawer
        title={
          createQuestionmodalData?.edit?.show && createQuestionmodalData?.edit?.id
            ? 'Edit Question'
            : 'Create Question'
        }
        destroyOnClose={true}
        maskClosable={false}
        width='90%'
        onClose={() =>
          setCreateQuestionmodalData({
            ...createQuestionmodalData,
            show: false,
            data: null,
          })
        }
        open={createQuestionmodalData?.show}
        footer={
          <>
            <Space className='float-right'>
              {createQuestionmodalData?.edit?.show &&
              createQuestionmodalData?.edit?.id ? (
                <>
                  <Button type='primary' onClick={() => editQuestion()}>
                    Update Question
                  </Button>
                </>
              ) : (
                <>
                  <Button type='primary' onClick={() => createQuestion(2)}>
                    Create Question For Review
                  </Button>
                  <Button onClick={() => createQuestion(1)}>
                    Create Question as Draft
                  </Button>
                </>
              )}
            </Space>
          </>
        }
        zIndex={1400}
      >
        <div className='th-bg-grey  th-br-8 py-2'>
          <ClickerQuestionFilters
            createQuestionmodalData={createQuestionmodalData}
            setCreateQuestionmodalData={setCreateQuestionmodalData}
            isFilter={false}
          />
        </div>

        {showQuestionAnswerSection() && (
          <>
            <div className='th-bg-grey  th-br-8 py-2 mt-2'>
              <div className='row'>
                <div className='col-12'>
                  <Title level={5}>Question</Title>
                  <ReactQuillEditor
                    value={createQuestionmodalData?.data?.question}
                    onChange={questionChangeHandler}
                    placeholder='Question goes here'
                    style={{ backgroundColor: '#fff' }}
                  />
                </div>
              </div>
            </div>
            <div className='th-bg-grey th-br-8 py-2 mt-2 options-container'>
              <div className='row'>
                <div className='col-12'>
                  <Title level={5}>Answer</Title>
                  <Row gutter={[0, 8]}>
                    {createQuestionmodalData?.data?.options?.map((eachOptions, index) => {
                      return (
                        <Col xs={24} key={index}>
                          <Row align='middle' gutter={[0, 3]}>
                            {createQuestionmodalData?.data?.filteredData?.questionType
                              ?.id !== 1 ? (
                              <Col sm={2} md={1}>
                                <Checkbox
                                  checked={eachOptions?.is_answer}
                                  className='th-clicker-checkbox'
                                  onChange={() => mcqSelectAnswerHandler(index)}
                                />
                              </Col>
                            ) : null}
                            <Col sm={20} md={22}>
                              <div className='d-flex align-items-center'>
                                <span className='mr-2'>
                                  {`${String.fromCharCode(65 + index)}.`}
                                </span>
                                <Input
                                  style={
                                    [4].includes(
                                      createQuestionmodalData?.data?.filteredData
                                        ?.questionType?.id
                                    )
                                      ? { color: '#000' }
                                      : {}
                                  }
                                  placeholder='Type the option here'
                                  value={eachOptions?.text}
                                  disabled={[4].includes(
                                    createQuestionmodalData?.data?.filteredData
                                      ?.questionType?.id
                                  )}
                                  onChange={(e) => mcqOptionHandler(e, index)}
                                />
                              </div>
                            </Col>
                            <Col sm={2} md={1}>
                              {index > 1 && (
                                <Popconfirm
                                  title='Are you sure to delete this option?'
                                  zIndex={1500}
                                  placement='topRight'
                                  onConfirm={() => handleRemoveOption(index)}
                                  // onCancel={cancel}
                                  okText='Yes'
                                  cancelText='No'
                                >
                                  <DeleteOutlined className='text-danger float-right th-20' />
                                </Popconfirm>
                              )}
                            </Col>

                            <Col sm={1}></Col>
                            <Col sm={12} md={15}>
                              {![null, ''].includes(eachOptions?.image_url) && (
                                <div className='d-flex align-items-center p-2 m-2'>
                                  <div className='image-container'>
                                    <Image
                                      width={120}
                                      preview={{
                                        zIndex: 1900,
                                      }}
                                      style={{
                                        border: '1px solid #d9d9d9',
                                        borderRadius: 4,
                                      }}
                                      src={eachOptions?.image_url}
                                    />
                                    <Popconfirm
                                      title='Are you sure to delete this image?'
                                      zIndex={1500}
                                      placement='topRight'
                                      onConfirm={() => removeImage(index)}
                                      // onCancel={cancel}
                                      okText='Yes'
                                      cancelText='No'
                                    >
                                      <CloseCircleOutlined className='image-delete-icon text-danger' />
                                    </Popconfirm>
                                  </div>
                                </div>
                              )}
                            </Col>
                            <Col sm={9} md={7}>
                              {![4].includes(
                                createQuestionmodalData?.data?.filteredData?.questionType
                                  ?.id
                              ) && (
                                <label
                                  style={{
                                    fontWeight: 500,
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    float: 'right',
                                  }}
                                  // htmlFor='file-upload'
                                >
                                  <input
                                    type='file'
                                    onChange={(e) => {
                                      attachImage(e, index);
                                    }}
                                    value=''
                                    name='images'
                                    id='file-upload'
                                    style={{ display: 'none' }}
                                    accept='image/*'
                                  />
                                  <FileImageOutlined /> Attach Image
                                </label>
                              )}
                            </Col>
                            <Col sm={2} md={1}></Col>
                          </Row>
                        </Col>
                      );
                    })}
                    {![1, 4].includes(
                      createQuestionmodalData?.data?.filteredData?.questionType?.id
                    ) && (
                      <Col xs={24}>
                        <div className='d-flex justify-content-center'>
                          <Button
                            className='btn add-another'
                            style={{ boxShadow: 'none', marginTop: '20px' }}
                            onClick={handleAddOption}
                          >
                            <PlusOutlined /> Add another option
                          </Button>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};

export default CreateEditQuestion;
