import React, { useState, useEffect } from 'react';
import Layout from '../../../containers/Layout';
import {
  Breadcrumb,
  Button,
  Col,
  Empty,
  message,
  Pagination,
  Row,
  Spin,
  Tabs,
} from 'antd';
import QuestionPaperFilter from './filter';
import CreateEditQuestionPaper from './createEditQuestionPaper';
import QuestionPaper from './questionPaper';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';

const { TabPane } = Tabs;

const ClickerQuestionPaper = () => {
  const [questionFilter, setQuestionFilter] = useState({});
  const [questionFiltered, setQuestionFiltered] = useState(false);
  const [questionPaperFilterLoading, setQuestionPaperFilterLoading] = useState(false);
  const [createQuestionPaperModalData, setCreateQuestionPaperModalData] = useState({
    show: false,
    edit: {
      id: null,
      show: false,
      paper_status: null,
    },
    data: null,
  });
  const [questionPaperList, setQuestionPaperList] = useState([]);
  const [questionPaperStatus, setQuestionPaperStatus] = useState('0');
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [fetchQuestionPaperFlag, setFetchQuestionPaperFlag] = useState(false);
  const pageLimit = 15;

  useEffect(() => {
    console.log(
      { questionFilter, createQuestionPaperModalData },
      'alllog ClickerQuestionPaper'
    );
  });

  useEffect(() => {
    if (questionFilter?.academicYear) {
      fetchQuestionPaperList({
        ...questionFilter,
        paper_status: questionPaperStatus === '0' ? null : parseInt(questionPaperStatus),
        page: 1,
      });
    }
  }, [questionPaperStatus]);

  useEffect(() => {
    if (fetchQuestionPaperFlag) {
      fetchQuestionPaperList();
    }
  }, [fetchQuestionPaperFlag]);

  const fetchQuestionPaperList = (params = {}) => {
    params = {
      academic_year: params?.academicYear?.id || questionFilter?.academicYear?.id || null,
      grade: params?.grade?.id || questionFilter?.grade?.id || null,
      subject:
        params?.subject?.subject?.id || questionFilter?.subject?.subject?.id || null,
      board: params?.board || questionFilter?.board || null,
      volume: params?.volume?.id || questionFilter?.volume?.id || null,
      module: params?.module?.id || questionFilter?.module?.id || null,
      chapter: params?.chapter?.id || questionFilter?.chapter?.id || null,
      key_concept: params?.keyConcept?.id || questionFilter?.keyConcept?.id || null,
      question_type: params?.questionType?.id || questionFilter?.questionType?.id || null,
      paper_status:
        params?.paper_status || questionPaperStatus !== '0'
          ? questionPaperStatus
          : null || null,
      page: params?.page || pageNo,
    };

    if (!params?.academic_year) {
      message.error('Please select academic year.');
      return;
    }

    if (!params?.grade) {
      message.error('Please select grade.');
      return;
    }

    if (!params?.subject) {
      message.error('Please select subject.');
      return;
    }

    setQuestionPaperFilterLoading(true);
    axiosInstance
      .get(`${endpoints.clickerQuestion.clickerQuestionsPaper}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setQuestionPaperList(result?.data?.result?.results);
          setPageNo(result?.data?.result?.current_page);
          setTotalPage(result?.data?.result?.count);
          setFetchQuestionPaperFlag(false);
          setQuestionFiltered(true);
        } else {
          setQuestionFiltered(false);
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || 'Something went wrong');
      })
      .finally(() => {
        setQuestionPaperFilterLoading(false);
      });
  };

  const questionPaperData = (
    <div>
      <Spin tip='Loading...' size='large' spinning={questionPaperFilterLoading}>
        <>
          {Array.isArray(questionPaperList) && questionPaperList?.length > 0 ? (
            <Row gutter={[16, 16]} style={{ rowGap: '8px' }}>
              {questionPaperList?.map((item, index) => {
                return (
                  <Col md={12}>
                    <QuestionPaper
                      currentPaper={item}
                      index={index}
                      key={index}
                      setFetchQuestionPaperFlag={setFetchQuestionPaperFlag}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <div
              className='row justify-content-center align-items-center'
              style={{ height: 300 }}
            >
              <div className='col-12 text-center'>
                <Spin
                  tip='Loading...'
                  size='large'
                  spinning={questionPaperFilterLoading}
                ></Spin>
                {questionPaperFilterLoading ? null : (
                  <Empty description={'No question Paper found'} />
                )}
              </div>
            </div>
          )}

          {questionPaperList?.length > 0 && (
            <div className='row justify-content-center mt-4'>
              <div className='col-12'>
                <div className='text-center'>
                  <Pagination
                    current={pageNo}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    pageSize={pageLimit}
                    total={totalPage}
                    style={{ justifyContent: 'center' }}
                    onChange={(currentPage) => {
                      setPageNo(currentPage);
                      fetchQuestionPaperList({
                        ...questionFilter,
                        page: currentPage,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      </Spin>
    </div>
  );

  return (
    <React.Fragment>
      <Layout>
        {/* Breadcrumb */}
        <div className='row pb-3'>
          <div className='col-md-9' style={{ zIndex: 2 }}>
            <Breadcrumb separator='>'>
              <Breadcrumb.Item className='th-grey-1 th-16'>
                Clicker Question
              </Breadcrumb.Item>
              <Breadcrumb.Item className='th-black-1 th-16'>
                Question Paper
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        <div className='row px-3'>
          <div className='col-md-12 th-bg-white th-br-8 px-1'>
            <div className='th-br-5 '>
              <QuestionPaperFilter
                isFilter={true}
                questionFilter={questionFilter}
                setQuestionFilter={setQuestionFilter}
                questionFilterLoading={questionPaperFilterLoading}
                fetchQuestionPaperList={fetchQuestionPaperList}
                createQuestionPaperModalData={createQuestionPaperModalData}
                setCreateQuestionPaperModalData={setCreateQuestionPaperModalData}
              />
            </div>

            <div className='th-br-5 p-2'>
              {questionFiltered ? (
                <div className=' px-1 th-br-8 th-tabs mt-0'>
                  <Tabs type='card' onChange={(e) => setQuestionPaperStatus(e)}>
                    <TabPane tab='All' key='0'>
                      {questionPaperData}
                    </TabPane>
                    <TabPane tab='Draft' key='1'>
                      {questionPaperData}
                    </TabPane>
                    <TabPane tab='For Review' key='2'>
                      {questionPaperData}
                    </TabPane>
                    <TabPane tab='Published' key='3'>
                      {questionPaperData}
                    </TabPane>
                  </Tabs>
                </div>
              ) : (
                <div
                  className='row justify-content-center align-items-center'
                  style={{ height: 300 }}
                >
                  <div className='col-12 text-center'>
                    <Spin
                      tip='Loading...'
                      size='large'
                      spinning={questionPaperFilterLoading}
                    ></Spin>
                    {questionPaperFilterLoading ? null : (
                      <Empty
                        description={'Please apply filter to fetch question paper'}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {createQuestionPaperModalData?.show && (
          <CreateEditQuestionPaper
            fetchQuestionPaperList={fetchQuestionPaperList}
            createQuestionPaperModalData={createQuestionPaperModalData}
            setCreateQuestionPaperModalData={setCreateQuestionPaperModalData}
          />
        )}
      </Layout>
    </React.Fragment>
  );
};

export default ClickerQuestionPaper;
