import React, { useState } from 'react';
import { Col, Drawer, message, Popconfirm, Row, Space, Spin, Tag } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import axiosInstance from '../../../config/axios';
import Question from './questions';
import endpoints from '../../../config/endpoints';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const QuestionPaper = ({ currentPaper, setFetchQuestionPaperFlag }) => {
  const [questionPaperPreviewData, setQuestionPaperPreviewData] = useState({
    show: false,
    data: null,
    loading: false,
  });
  const history = useHistory();

  const fetchQuestionPaperData = (params = {}) => {
    setQuestionPaperPreviewData((prevState) => ({
      ...prevState,
      loading: true,
      show: true,
    }));
    axiosInstance
      .get(`${endpoints.clickerQuestion.clickerQuestionsPaper}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setQuestionPaperPreviewData((prevState) => ({
            ...prevState,
            data: result?.data?.result?.results[0],
          }));
        }
      })

      .catch((error) => {
        message.error(error?.response?.data?.message || 'Something went wrong');
      })
      .finally(() => {
        setQuestionPaperPreviewData((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  };

  const handleQuestionPaperStatusUpdate = (id, paper_status) => {
    let payload = {
      id,
      paper_status,
    };
    axiosInstance
      .put(endpoints.clickerQuestion.clickerQuestionsPaper, payload)
      .then((response) => {
        if (response.data.status_code === 200) {
          message.success(response.data.message);
          setFetchQuestionPaperFlag(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleCloseDrawer = () => {
    setQuestionPaperPreviewData({
      ...questionPaperPreviewData,
      data: null,
      show: false,
      loading: false,
    });
  };

  const questionPaperData = (
    <div>
      <Spin tip='Loading...' size='large' spinning={questionPaperPreviewData?.loading}>
        <>
          {questionPaperPreviewData?.data?.questions?.map((item, index) => {
            return (
              <Row align='middle' key={index} className='th-br-8 th-bg-grey'>
                <Col md={24}>
                  <Question currentQuestion={item} index={index} />
                </Col>
              </Row>
            );
          })}
        </>
      </Spin>
    </div>
  );

  return (
    <React.Fragment>
      <Row
        gutter={[8, 8]}
        className='shadow-sm th-br-8 th-pointer'
        style={{ outline: '1px solid #f3f3f3', backgroundColor: '#fdfdfd' }}
      >
        <div className='row justify-content-between px-3 py-2 border-bottom border-bottom-primary'>
          <span className='th-18 th-blue'>{currentPaper?.title}</span>
          <Tag
            // icon={<ReloadOutlined />}
            color={
              currentPaper?.paper_status === 1
                ? 'yellow'
                : currentPaper?.paper_status === 2
                ? 'blue'
                : currentPaper?.paper_status === 3
                ? 'green'
                : 'green'
            }
            className='th-pointer th-12 m-0'
            style={{ height: 'fit-content' }}
          >
            {currentPaper?.paper_status === 1
              ? 'Draft'
              : currentPaper?.paper_status === 2
              ? 'Review'
              : currentPaper?.paper_status === 3
              ? 'Published'
              : currentPaper?.paper_status}
          </Tag>
        </div>
        <div className='row justify-content-between px-3 py-1'>
          <span>Total Question : {currentPaper?.total_questions} </span>
          <span> Total Marks : {currentPaper?.total_marks}</span>
        </div>
        <div className='row justify-content-between px-3 pb-2'>
          <Space>
            <Popconfirm
              title='Are you sure to delete this question?'
              //   onConfirm={() => handleQuestionDelete(currentPaper?.id)}
              okText='Yes'
              cancelText='No'
              placement='topRight'
            >
              <Tag color='error' className='th-pointer th-16 m-0'>
                {/* Delete */}
                <DeleteOutlined />
              </Tag>
            </Popconfirm>
            <span className='font-italic text-secondary'>
              {moment(currentPaper?.created_at)?.format('DD-MM-YYYY')}
            </span>
          </Space>
          <Space>
            <Tag
              icon={<EditOutlined />}
              color='processing'
              onClick={() => {
                history.push(`/clicker-question/edit-question-paper/${currentPaper.id}`);
              }}
              className='th-pointer th-12'
            >
              Edit
            </Tag>
            <Tag
              icon={<EyeOutlined />}
              color='green'
              onClick={() => fetchQuestionPaperData({ qp_id: currentPaper?.id })}
              className='th-pointer th-12 m-0'
            >
              View
            </Tag>
          </Space>
        </div>
      </Row>

      <Drawer
        title={questionPaperPreviewData?.data?.title ?? 'Question paper'}
        destroyOnClose={true}
        maskClosable={false}
        width='70%'
        onClose={() => handleCloseDrawer()}
        open={questionPaperPreviewData?.show}
        footer={
          <>
            <Popconfirm
              title='Are you sure to update status of this question?'
              zIndex={1800}
              onConfirm={() =>
                handleQuestionPaperStatusUpdate(
                  currentPaper?.id,
                  currentPaper?.paper_status === 1
                    ? 2
                    : currentPaper?.paper_status === 2
                    ? 3
                    : currentPaper?.paper_status === 3
                    ? 2
                    : null
                )
              }
              okText='Yes'
              cancelText='No'
              placement='topRight'
            >
              {/* draft - 1, review -2, publish -3, */}
              {/* status 1 => move to review from draft */}
              {/* status 2 => move to Publish from review */}
              {/* status 3 => move to Review from publish */}
              <Tag
                icon={<ReloadOutlined />}
                color={
                  currentPaper?.paper_status === 1
                    ? 'yellow'
                    : currentPaper?.paper_status === 2
                    ? 'blue'
                    : currentPaper?.paper_status === 3
                    ? 'green'
                    : 'green'
                }
                className='th-pointer th-12 mt-1'
              >
                Move to{' '}
                {currentPaper?.paper_status === 1
                  ? 'Review'
                  : currentPaper?.paper_status === 2
                  ? 'Publish'
                  : currentPaper?.paper_status === 3
                  ? 'Review'
                  : null}
              </Tag>
            </Popconfirm>
          </>
        }
        zIndex={1400}
      >
        <div
          className='row p-2 mb-3 pl-4 pr-4 justify-content-between th-br-8'
          style={{ backgroundColor: '#dee2e6' }}
        >
          <div className='d-flex flex-row'>
            <div className='th-fw-600'>
              {questionPaperPreviewData?.data?.grade[0]?.grade_name}
            </div>
          </div>
          <div className='d-flex flex-row'>
            <div className='th-fw-600 mr-1'>Subject:</div>
            <div>{questionPaperPreviewData?.data?.subject[0]?.subject_name}</div>
          </div>
          {/* <div className='d-flex flex-row'>
            <div className='th-fw-600 mr-1'>Duration:</div>
            <div>{questionPaperPreviewData?.data?.total_duration} Min</div>
          </div> */}
          <div className='d-flex flex-row'>
            <div className='th-fw-600 mr-1'>Total Marks:</div>
            <div> {questionPaperPreviewData?.data?.total_marks}</div>
          </div>
        </div>
        <div className='row'>
          <div
            className='col-12 mb-2 px-2 pt-2 th-br-8'
            style={{ backgroundColor: '#dee2e6' }}
          >
            <div className='th-fw-700 th-16'>Instructions</div>
            <p style={{ whiteSpace: 'pre-wrap' }}>
              {questionPaperPreviewData?.data?.description}
            </p>
          </div>
        </div>
        {questionPaperData}
      </Drawer>
    </React.Fragment>
  );
};

export default QuestionPaper;
