import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../../../config/axios';
import { Form, Select, message, Button, Input, InputNumber, Spin } from 'antd';
import { FilterOutlined, PlusCircleOutlined } from '@ant-design/icons';
import './style.scss';
import endpoints from '../../../config/endpoints';

const { Option } = Select;
const ClickerQuestionFilters = ({
  createQuestionmodalData,
  setCreateQuestionmodalData,
  isFilter,
  onFilterQuestionList,
  questionFilterLoading,
  questionFilter,
  setQuestionFilter,
}) => {
  const [showAllFilters, setShowAllFilters] = useState(!isFilter);
  const [boardId, setBoardId] = useState('');

  //Filters dropdown State
  const [academicYearList, setAcademicYearList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [volumeList, setVolumeList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [keyConceptList, setKeyConceptList] = useState([]);
  const [questionTypeList, setQuestionTypeList] = useState([]);

  //Filter State
  const [filteredData, setFilteredData] = useState({
    academicYear: null,
    grade: null,
    subject: null,
    board: null,
    volume: null,
    module: null,
    chapter: null,
    keyConcept: null,
    questionType: null,
    questionMarks: null,
  });

  //Default
  useEffect(() => {
    fetchBoardListData();
    fetchAcademicYearList();
    fetchGradeData();
    fetchVolumeListData();
    fetchQuestionTypeListData();
  }, []);

  //for Create question
  useEffect(() => {
    if (
      !isFilter &&
      !createQuestionmodalData?.edit?.show &&
      !createQuestionmodalData?.edit?.id
    ) {
      setCreateQuestionmodalData({
        ...createQuestionmodalData,
        data: {
          ...createQuestionmodalData.data,
          filteredData: filteredData,
        },
      });
    }
  }, [filteredData, isFilter]);

  //for Edit question
  useEffect(() => {
    if (
      !isFilter &&
      createQuestionmodalData?.edit?.show &&
      createQuestionmodalData?.edit?.id
    ) {
      setCreateQuestionmodalData({
        ...createQuestionmodalData,
        data: {
          ...createQuestionmodalData.data,
          filteredData: filteredData,
        },
      });
    }
  }, [filteredData]);
  useEffect(() => {
    if (
      !isFilter &&
      createQuestionmodalData?.edit?.show &&
      createQuestionmodalData?.edit?.id
    ) {
      setFilteredData(createQuestionmodalData?.data?.filteredData);
    }
  }, [createQuestionmodalData]);

  //Set filter at index.js
  useEffect(() => {
    if (
      isFilter &&
      !createQuestionmodalData?.edit?.show &&
      !createQuestionmodalData?.edit?.id
    ) {
      setQuestionFilter({
        ...questionFilter,
        ...filteredData,
      });
    }
  }, [filteredData]);

  //Subject
  useEffect(() => {
    if (filteredData?.grade) {
      fetchSubjectData({
        grade: filteredData?.grade?.id,
      });
    } else {
      setSubjectList([]);
      setModuleList([]);
      setChapterList([]);
      setKeyConceptList([]);
    }
  }, [filteredData?.grade]);

  //Module
  useEffect(() => {
    if (filteredData?.grade && filteredData?.subject && filteredData?.volume) {      
      fetchModuleListData({
        volume: filteredData?.volume?.id,
        grade_subject: filteredData?.subject?.id,
        board: boardId,
        academic_year: filteredData?.academicYear?.id
      });
    } else {
      setModuleList([]);
      setChapterList([]);
      setKeyConceptList([]);
    }
  }, [filteredData?.grade, filteredData?.subject, filteredData?.volume]);

  //chapter
  useEffect(() => {
    if (
      filteredData?.grade &&
      filteredData?.subject &&
      filteredData?.volume &&
      filteredData?.module &&
      boardId
    ) {
      fetchChapterListData({
        grade_subject: filteredData?.subject?.id,
        volume: filteredData?.volume?.id,
        lt_module: filteredData?.module?.id,
        board: boardId,
      });
    } else {
      setChapterList([]);
      setKeyConceptList([]);
    }
  }, [
    filteredData?.grade,
    filteredData?.subject,
    filteredData?.volume,
    filteredData?.module,
    boardId,
  ]);

  //Key Concept
  useEffect(() => {
    if (
      filteredData?.grade &&
      filteredData?.subject &&
      filteredData?.volume &&
      filteredData?.module &&
      filteredData?.chapter
    ) {
      fetchKeyConceptListData({
        chapter: filteredData?.chapter?.id,
      });
    } else {
      setKeyConceptList([]);
    }
  }, [
    filteredData?.grade,
    filteredData?.subject,
    filteredData?.volume,
    filteredData?.module,
    filteredData?.chapter,
  ]);

  // FETCH API's

  //Academic Year List

  const fetchAcademicYearList = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setAcademicYearList(result?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Grade List
  const fetchGradeData = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setGradeList(res?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Subject List
  const fetchSubjectData = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.gradeSubjectMappingList}`, {
        params: { ...params },
      })
      .then((res) => {
        if (res.data.status_code === 200) {
          setSubjectList(res?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Board List
  const fetchBoardListData = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.board}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          let data = result?.data?.result?.filter(
            (item) => item?.board_name === 'CBSE'
          )[0];
          setBoardId(data?.id);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Volume List
  const fetchVolumeListData = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.volumes}`, {
        headers: {
          'x-api-key': 'vikash@12345#1231',
        },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setVolumeList(result?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Module List
  const fetchModuleListData = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.module}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setModuleList(result?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Chapter List
  const fetchChapterListData = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.chapters}`, { params: { ...params } })
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setChapterList(res?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Key Concept
  const fetchKeyConceptListData = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.keyConceptTableData}`, { params: { ...params } })
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setKeyConceptList(res?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Question Type
  const fetchQuestionTypeListData = () => {
    axiosInstance
      .get(`${endpoints.clickerQuestion.questionType}`)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setQuestionTypeList(res?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  // handle Filter
  const handleFilterChange = (keyname, value) => {
    if (keyname === 'academicYear') {
      setFilteredData({
        ...filteredData,
        academicYear: value ? value.optionsData : null,
        grade: null,
        subject: null,
        board: boardId,
        volume: null,
        module: null,
        chapter: null,
        keyConcept: null,
        questionType: null,
      });
      return;
    }

    if (keyname === 'grade') {
      setFilteredData({
        ...filteredData,
        grade: value ? value.optionsData : null,
        subject: null,
        volume: null,
        module: null,
        chapter: null,
        keyConcept: null,
        questionType: null,
      });
      return;
    }

    if (keyname === 'subject') {
      setFilteredData({
        ...filteredData,
        subject: value ? value.optionsData : null,
        volume: null,
        module: null,
        chapter: null,
        keyConcept: null,
        questionType: null,
      });
      return;
    }

    if (keyname === 'volume') {
      setFilteredData({
        ...filteredData,
        volume: value ? value.optionsData : null,
        module: null,
        chapter: null,
        keyConcept: null,
        questionType: null,
      });
      return;
    }

    if (keyname === 'module') {
      setFilteredData({
        ...filteredData,
        module: value ? value.optionsData : null,
        chapter: null,
        keyConcept: null,
        questionType: null,
      });
      return;
    }

    if (keyname === 'chapter') {
      setFilteredData({
        ...filteredData,
        chapter: value ? value.optionsData : null,
        keyConcept: null,
        questionType: null,
      });
      return;
    }

    if (keyname === 'keyConcept') {
      setFilteredData({
        ...filteredData,
        keyConcept: value ? value.optionsData : null,
        questionType: null,
      });
      return;
    }

    if (keyname === 'questionType') {
      setFilteredData({
        ...filteredData,
        questionType: value ? value.optionsData : null,
      });
      return;
    }

    setFilteredData({ ...filteredData, [keyname]: value?.optionsData || value });
  };

  const volumeOptions = volumeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.volume_name}
      </Option>
    );
  });

  const academicYearOptions = academicYearList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.session_year}
      </Option>
    );
  });

  const gradeOptions = gradeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.grade_name}
      </Option>
    );
  });

  const subjectOptions = subjectList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.subject?.subject_name}
      </Option>
    );
  });

  const moduleOptions = moduleList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.lt_module_name}
      </Option>
    );
  });

  const chapterOptions = chapterList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.chapter_name}
      </Option>
    );
  });

  const keyConceptOptions = keyConceptList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.topic_name}
      </Option>
    );
  });

  const questionTypeOptions = questionTypeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.category_name}
      </Option>
    );
  });

  return (
    <>
      <Form layout='vertical' className='th-clicker-form py-2'>
        <div className='row align-items-center'>
          <div className='col-md-2 col-6 mb-2'>
            <label htmlFor='AcademicYear' className='' title='Academic Year*'>
              Academic Year *
            </label>
            <Select
              showSearch
              placeholder='Academic Year*'
              optionFilterProp='children'
              className='w-100'
              allowClear
              getPopupContainer={(trigger) => trigger.parentNode}
              dropdownMatchSelectWidth={false}
              filterOption={(input, options) => {
                return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              disabled={
                !isFilter &&
                createQuestionmodalData?.edit?.show &&
                createQuestionmodalData?.edit?.id
              }
              value={filteredData?.academicYear?.id}
              onChange={(e, row) => handleFilterChange('academicYear', row)}
            >
              {academicYearOptions}
            </Select>
          </div>
          <div className='col-md-2 col-6 mb-2'>
            <label htmlFor='grade' className='' title='Grade*'>
              Grade *
            </label>
            <Select
              showSearch
              placeholder='Select Grade*'
              optionFilterProp='children'
              className='w-100'
              allowClear
              getPopupContainer={(trigger) => trigger.parentNode}
              dropdownMatchSelectWidth={false}
              filterOption={(input, options) => {
                return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              disabled={
                !isFilter &&
                createQuestionmodalData?.edit?.show &&
                createQuestionmodalData?.edit?.id
              }
              value={filteredData?.grade?.id ?? null}
              onChange={(e, row) => handleFilterChange('grade', row)}
            >
              {gradeOptions}
            </Select>
          </div>
          <div className='col-md-2 col-6 mb-2'>
            <label htmlFor='Subject' className='' title='Subject*'>
              Subject *
            </label>
            <Select
              showSearch
              placeholder='Select Subject*'
              optionFilterProp='children'
              className='w-100'
              allowClear
              getPopupContainer={(trigger) => trigger.parentNode}
              dropdownMatchSelectWidth={false}
              filterOption={(input, options) => {
                return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              disabled={
                !isFilter &&
                createQuestionmodalData?.edit?.show &&
                createQuestionmodalData?.edit?.id
              }
              value={filteredData?.subject?.id}
              onChange={(e, row) => handleFilterChange('subject', row)}
            >
              {subjectOptions}
            </Select>
          </div>

          {showAllFilters && (
            <>
              <div className='col-md-2 col-6 mb-2'>
                <label htmlFor='Volume' className='' title='Volume*'>
                  Volume
                </label>
                <Select
                  showSearch
                  placeholder='Select Volume'
                  optionFilterProp='children'
                  className='w-100'
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  disabled={
                    !isFilter &&
                    createQuestionmodalData?.edit?.show &&
                    createQuestionmodalData?.edit?.id
                  }
                  value={filteredData?.volume?.id}
                  onChange={(e, row) => handleFilterChange('volume', row)}
                >
                  {volumeOptions}
                </Select>
              </div>
              <div className='col-md-2 col-6 mb-2'>
                <label htmlFor='Module' className='' title='Module*'>
                  Module
                </label>
                <Select
                  showSearch
                  allowClear
                  placeholder='Select Module'
                  optionFilterProp='children'
                  className='w-100'
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  disabled={
                    !isFilter &&
                    createQuestionmodalData?.edit?.show &&
                    createQuestionmodalData?.edit?.id
                  }
                  value={filteredData?.module?.id}
                  onChange={(e, row) => handleFilterChange('module', row)}
                >
                  {moduleOptions}
                </Select>
              </div>
              <div className='col-md-2 col-6 mb-2'>
                <label htmlFor='Chapter' className='' title='Chapter*'>
                  Chapter
                </label>
                <Select
                  showSearch
                  placeholder='Select Chapter'
                  optionFilterProp='children'
                  className='w-100'
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  disabled={
                    !isFilter &&
                    createQuestionmodalData?.edit?.show &&
                    createQuestionmodalData?.edit?.id
                  }
                  value={filteredData?.chapter?.id}
                  onChange={(e, row) => handleFilterChange('chapter', row)}
                >
                  {chapterOptions}
                </Select>
              </div>
              <div className='col-md-2 col-6 mb-2'>
                <label htmlFor='KeyConcept' className='' title='Key Concept*'>
                  Key Concept
                </label>
                <Select
                  showSearch
                  placeholder='Select Key Concept'
                  optionFilterProp='children'
                  className='w-100'
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  disabled={
                    !isFilter &&
                    createQuestionmodalData?.edit?.show &&
                    createQuestionmodalData?.edit?.id
                  }
                  value={filteredData?.keyConcept?.id}
                  onChange={(e, row) => handleFilterChange('keyConcept', row)}
                >
                  {keyConceptOptions}
                </Select>
              </div>

              <div className='col-md-2 col-6 mb-2'>
                <label htmlFor='QuestionType' className='' title='Question Type *'>
                  Question Type
                </label>
                <Select
                  placeholder='Question Type'
                  optionFilterProp='children'
                  className='w-100'
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  disabled={
                    !isFilter &&
                    createQuestionmodalData?.edit?.show &&
                    createQuestionmodalData?.edit?.id
                  }
                  value={filteredData?.questionType?.id}
                  onChange={(e, row) => handleFilterChange('questionType', row)}
                >
                  {questionTypeOptions}
                </Select>
              </div>
              {!isFilter && (
                <div className='col-md-2 col-6 mb-2'>
                  <label htmlFor='QuestionType' className='' title='Question Type *'>
                    Question Mark *
                  </label>
                  <InputNumber
                    className='w-100'
                    controls={false}
                    placeholder='Question Mark'
                    onChange={(value) => handleFilterChange('questionMarks', value)}
                    value={filteredData?.questionMarks}
                    precision={2}
                  />
                </div>
              )}
            </>
          )}

          {isFilter ? (
            <>
              <div className='col-sm-2 col-6 mt-3'>
                <Button
                  // ghost
                  // type='default'
                  className='th-br-4 w-100 mt-1 th-button'
                  loading={questionFilterLoading}
                  onClick={() => onFilterQuestionList({ ...filteredData, page: 1 })}
                >
                  Filter
                </Button>
              </div>
              <div className='col-1 pl-0 mt-3'>
                <Button
                  type='link'
                  className='th-br-4 mt-1 pl-0'
                  size='small'
                  icon={<FilterOutlined />}
                  onClick={() => setShowAllFilters((showAllFilters) => !showAllFilters)}
                >
                  <span className=''>Show {showAllFilters ? 'Less' : 'All'}</span>
                </Button>
              </div>

              <div className='col-3 mt-3'>
                <Button
                  type='primary'
                  className='th-br-4 px-5 mt-1 float-right'
                  icon={<PlusCircleOutlined />}
                  onClick={() =>
                    setCreateQuestionmodalData({
                      ...createQuestionmodalData,
                      show: true,
                      data: null,
                      edit: {},
                    })
                  }
                >
                  Create Question
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </Form>
    </>
  );
};

export default ClickerQuestionFilters;
